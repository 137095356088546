import React from 'react'
import { Slider } from '../components/Carousel'

function Clips() {
  return (
    <section id='clips' className='md:p-16 p-8'>
    <div className='flex flex-col justify-center items-start gap-y-6'>
    <h1 className="md:text-5xl ss:text-3xl text-2xl  font-semibold text-start">
    Clips
      </h1>
          <Slider />
    </div>


</section> 
  )
}

export default Clips