import React from "react";
import PropTypes from "prop-types";

const Media = ({ Icon, firstText, secondText, link }) => {
  return (
    <div className="flex md:flex-row md:items-center  gap-x-3 flex-col">
      {Icon && (
        <a href={link} target="_blank" rel="noopener noreferrer">
          <Icon className="text-blue text-5xl md:text-7xl" />
        </a>
      )}
      <div className="flex flex-col gap-y-0">
        <p className="text-white md:text-2xl font-semibold mb-2 hidden md:block">
          {firstText}
        </p>
        <p className="text-blue md:text-2xl font-semibold hidden md:block">{secondText}</p>
      </div>
    </div>
  );
};

Media.propTypes = {
  Icon: PropTypes.elementType,
  firstText: PropTypes.string.isRequired,
  secondText: PropTypes.string.isRequired,
  link: PropTypes.string,
};

export default Media;
