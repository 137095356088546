import MySkills from "./scenes/TotalReach";
import LineGradient from "./components/LineGradient";
import Projects from "./scenes/Emissions";
import Contact from "./scenes/Contact";
import Footer from "./scenes/Footer";
import Testimonials from "./scenes/Collab";
import Sidebar from "./components/sidebar/Sidebar";
import Landing from "./scenes/Landing";
import Movies from "./scenes/Movies";
import TotalReach from "./scenes/TotalReach";
import Partners from "./scenes/Emissions";
import Collab from "./scenes/Collab";
import Clips from "./scenes/Clips";


function App() {
  return (
    <div className="app ">
      <Sidebar />
      <Landing/>
      <TotalReach />
      <Clips/>
      <Movies />
      <LineGradient />
      <Partners />
      <LineGradient />
      <Collab />
      <Contact/>
      <Footer/>
    </div>
  );
}

export default App;
