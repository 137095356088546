const Footer = () => {
  return (
    <footer className="h-20  bg-gray-600  flex justify-center items-center">
      <div className="w-10/12 mx-auto">
        <div className="md:flex justify-center text-center">
          <p className="text-xs md:text-xl text-white font-semibold	">
            Imen Cherif 2024 © | All
            Rights Reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
