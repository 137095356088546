import { motion } from "framer-motion";

const variants = {
  open: {
    transition: {
      staggerChildren: 0.1,
    },
  },
  closed: {
    transition: {
      staggerChildren: 0.05,
      staggerDirection: -1,
    },
  },
};
const itemVariants = {
  open: {
    y: 0,
    opacity: 1,
  },
  closed: {
    y: 50,
    opacity: 0,
  },
};

const Links = ({ closeNavbar }) => {
  const items = [
    { to: "homepage", label: "Acceuil" },
    { to: "clips", label: "Clips" },
    { to: "movies", label: "Films" },
    { to: "emissions", label: "Emissions" },
    { to: "collab", label: "Booking" },
  ];
  const handleClick = (event, id) => {
    event.preventDefault(); // Prevent the default anchor behavior
    smoothScrollTo(id); // Call the smooth scrolling function
    closeNavbar(); // Close the navbar

  };
  const smoothScrollTo = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };
  
  return (
    <motion.div className="links" variants={variants}>
      {items.map((item) => (
        <motion.a
        key={item.label}
        href={`#${item.to}`} // Keep the href for accessibility reasons
        onClick={(event) => handleClick(event, item.to)}

        variants={itemVariants}

          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
          className="text-xl md:text-5xl"
        >
          {item.label}
        </motion.a>
      ))}
    </motion.div>
  );
};

export default Links;