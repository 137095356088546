import AwardElement from "../components/AwardElement";
import awardCinema from "../assets/award-cinema.png";
import ParticlesComponent from "../components/Particles/particles";
import "../fonts/font.css"
const Landing = () => {
  return (
    <section id="homepage" className="custom-bg relative ">
      <ParticlesComponent
        id="particles-js"
        style={{
          position: "absolute",

          top: 0,
          left: 0,
          width: "100%",
          height: "200px",
        }}
      />

      <div className="flex flex-col justify-center items-center w-full p-8 ss:p-24 ">
        <div className="flex flex-col justify-center items-center pt-24 mb-10">
          <h1 className="md:text-9xl ss:text-7xl text-5xl font-badscript font-semibold">Imen Cherif</h1>
          <h2 className="md:text-5xl xs:text-xl pt-6 text-[#eed28c] font-badscript">
            Actrice-Styliste-Mannequn
          </h2>
        </div>
        {/* awards section */}
        <div className="flex justify-center items-center mt-16 sm:space-x-10 md:space-x-44">
          <div className="flex-1 flex flex-col space-y-0 items-center md:space-y-8 ">
            <AwardElement
              year="2008"
              title="مهرجان"
              subtitle="الموسيقى"
              buttonText=""
            />{" "}
            <AwardElement
              year=""
              title="Meilleure chanson"
              subtitle="pour les enfants"
              buttonText="انستي"
            />{" "}
          </div>

          <div className="flex-1 flex flex-col space-y-0 items-center md:space-y-11 ">
            <img src={awardCinema} alt="award cinema" className=" w-20" />
            <AwardElement
              year="2008"
              title="Festival Arabe"
              subtitle="du vidéo Clip"
              buttonText="Au Caire"
            />{" "}
          </div>

          <div className="flex-1 flex flex-col space-y-0 items-center md:space-y-8 ">
            <AwardElement
              year=""
              title="Oscar"
              subtitle="d'Arabe"
              buttonText=""
            />{" "}
            <AwardElement
              year="2016"
              title="Hassan Megri"
              subtitle=" Été des Oudayas"
              buttonText=""
            />{" "}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Landing;
