import React, { useEffect, useRef, useState } from 'react';
import "./card.css"
const Card = ({ imageUrl, text , videoUrl }) => {

    const cardRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        setIsVisible(entry.isIntersecting);
      });
    }, {
      rootMargin: '0px',
      threshold: 0.1, 
    });

    if (cardRef.current) {
      observer.observe(cardRef.current);
    }

    return () => {
      if (cardRef.current) {
        observer.disconnect();
      }
    };
  }, []);
  
  return (
    <div ref={cardRef} className={`transform transition-opacity duration-500 ${isVisible ? 'slide-up-fade-in' : 'opacity-0'}`}>
 <a href={videoUrl}  target="_blank" rel="noopener noreferrer" className="inline-block overflow-hidden hover:cursor-pointer">

<div className="relative inline-block overflow-hidden hover:cursor-pointer hover:scale-105  transition-transform duration-300 ease-in-out">
  {/* Add transition to the container, and use group-hover for child elements */}
  <div className="transition-all duration-300 ease-in-out group"
       onMouseEnter={() => {}}
       onMouseLeave={() => {}}>
    {/* Scale the image on group hover */}
    <img src={imageUrl} alt="" className=" transform  group-hover:opacity-40 transition-transform duration-300 ease-in-out" />
    {/* Change background and text color on group hover */}
    <div className="absolute bottom-4 ss:bottom-8 font-semibold sm:text-xl text-sm left-0 right-0 w-full bg-black border-y-2 sm:border-y-4 border-blue
     text-white text-center p-2 group-hover:bg-blue group-hover:text-black group-hover:w-[90%] group-hover:ml-6
      transition-all duration-300 ease-in-out">
      {text}
    </div>
  </div>
</div>
</a>
    </div>
   
  );
};

export default Card;