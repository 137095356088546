import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import Loader from "./scenes/Loader";

const root = ReactDOM.createRoot(document.getElementById("root"));

const RootComponent = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 10000);

    return () => clearTimeout(timer);
  }, []);

  return (
  //  <React.StrictMode>{isLoading ? <Loader /> : <App />}</React.StrictMode>
    <React.StrictMode> <App /> </React.StrictMode>
  );
};

root.render(<RootComponent />);
