import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

const useScrollAnimation = () => {
  const [animationTriggered, setAnimationTriggered] = useState(false);
  const [ref, inView] = useInView({ threshold: 0.5 });

  useEffect(() => {
    if (inView) {
      setAnimationTriggered(true);
    } else {
      setAnimationTriggered(false);
    }
  }, [inView]);

  const resetAnimationTrigger = () => {
    setAnimationTriggered(false);
  };

  return [ref, animationTriggered, resetAnimationTrigger];
};

export default useScrollAnimation;
