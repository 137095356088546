import React from "react";
import collab from "../assets/collab.png";

const Collab = () => {


  return (
    <section
      id="collab"
      className=" lg:p-16 md:p-10 p-6 flex flex-col lg:flex-row justify-center items-center gap-x-20"
      
    >
   
        <div className="flex flex-col ">
          <img
            src={collab}
            alt="additional information"
            className="rounded-full border-4 border-dashed border-blue w-48 sm:w-64 lg:w-72"
          />
        </div>

       
        <div className="flex flex-col ">
          <p className="sm:text-4xl lg:text-6xl text-center md:text-left font-semibold text-white mt-8 lg:mb-2">
            • Management & Booking:
           
          </p>
          <span className="sm:text-5xl text-xl font-bold text-blue">
              + (216) 52 277 722
            </span>
        </div>
    </section>
  );
};

export default Collab;