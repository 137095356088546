import useScrollAnimation from "../hooks/useScrollAnimation";
import { motion } from "framer-motion";
import Media from "../components/Media";
import { RiFacebookBoxLine } from "react-icons/ri";
import { RxInstagramLogo } from "react-icons/rx";
import { BsTiktok } from "react-icons/bs";
import { LiaSnapchatSquare } from "react-icons/lia";
import { AiOutlineYoutube } from "react-icons/ai";
import { FaUsers } from "react-icons/fa";
import { SlSocialSpotify } from "react-icons/sl";

import ParticleAnimation from "../components/Particles";

const TotalReach = () => {
  const [ref, animationTriggered] = useScrollAnimation();

  return (
    <section id="skills" className="pt-10 pb-24  justify-center   bg-[#060706]">
      <ParticleAnimation className="absolute inset-0 z-0 " />

      <motion.div
        className=" z-10"
        ref={ref}
        initial={animationTriggered ? "visible" : "hidden"}
        animate={animationTriggered ? "visible" : "hidden"}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, y: 0 },
          visible: { opacity: 1, y: -50 },
        }}
      >
        <p className="font-bold text-2xl md:text-7xl mb-5 text-center flex items-center justify-center mt-10">
          <FaUsers className="mr-3 text-blue" />
          Social Media
        </p>

        <div className="flex  flex-col md:flex-row  mt-10 justify-center">
          <div className="flex flex-row justify-around mt-7 md:mt-0 md:mr-10 space-x-9">
            <Media
              Icon={RiFacebookBoxLine}
              firstText="Facebook"
              secondText="398 K"
              link="https://www.facebook.com/ImenCherifOfficial?locale=fr_FR"
            />
            <Media
              Icon={RxInstagramLogo}
              firstText="Instagram"
              secondText="960 k "
              link="https://www.instagram.com/imen.cherif.officiel/"
            />
            <Media
              Icon={BsTiktok}
              firstText="TikTok"
              secondText="268 K"
              link="https://www.tiktok.com/@imencherifofficiel?lang=fr"
            />
          </div>
          <div className="flex flex-row justify-evenly mt-7 md:mt-0 md:justify-evenly space-x-9">
            <Media
              Icon={SlSocialSpotify}
              firstText="Spotify"
              secondText="1897 "

              link="https://open.spotify.com/intl-fr/artist/7dtVJZQB6sesoWVAXHEUln?autoplay=true"
            />
            <Media
              Icon={AiOutlineYoutube}
              firstText="YouTube"
              secondText="37.2 K"
              link="https://www.youtube.com/c/ImenCherif"
            />
          </div>
        </div>
      </motion.div>
    </section>
  );
};

export default TotalReach;
