import React from 'react';

function AwardElement({ year, title, subtitle, buttonText }) {
  return (
    <div className="relative award-bg-xs xs:award-bg bg-center bg-no-repeat bg-contain h-48 flex flex-col items-center justify-center p-10 lg:p-16">
      <div className="text-center w-[200%] xs:w-full lg:space-y-1">
        <p className="text-white text-xssx xs:text-xss lg:text-sm font-light">{year}</p>
        <p className="text-white text-xssx xs:text-xss lg:text-lg font-semibold">{title}</p>
        <p className="text-yellow-400 text-xssx xs:text-xss lg:text-lg font-medium">{subtitle}</p>
        <p className="text-white text-xssx xs:text-xss lg:text-lg font-light">{buttonText}</p>
      </div>
    </div>
  );
}

export default AwardElement;
