import backgroundImg from "../assets/contactusbg.jpg";
import contactimg from "../assets/image-modified.png";
import { useState } from "react";
import emailjs from "@emailjs/browser";
import { useRef } from "react";

const Contact = () => {
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const ref = useRef(null);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    const name = form.current.querySelector("#name").value;
    const email = form.current.querySelector("#email").value;
    const collaboration = form.current.querySelector("#collaboration").value;

    if (!name || !email || !collaboration) {
      setShowErrorMessage(true);
      return;
    }

    const selectedOption = form.current.querySelector("select").value;
    if (!selectedOption || selectedOption === "placeholder") {
      setShowErrorMessage(true);
      return;
    }

    let serviceId, templateId, publicKey;
    if (selectedOption === "reservation de spectacle") {
      serviceId = "service_2jcpreg";
      templateId = "template_gvkwxl3";
      publicKey = "biL1dYBWPIpoovbEo";
    } else if (selectedOption === "publicité et convention") {
      serviceId = "service_j2e3euk";
      templateId = "template_pii2kaz";
      publicKey = "VQ3PvGpVGVMstoSwb";
    } else if (selectedOption === "casting/cinema") {
      serviceId = "service_u6cuv9p";
      templateId = "template_r1isb7o";
      publicKey = "UXP5igYOF-MuZbxMk";
    }

    emailjs
      .sendForm(serviceId, templateId, form.current, publicKey)
      .then((result) => {
        form.current.reset();
        setShowErrorMessage(false);
        setShowSuccessMessage(true);
        setTimeout(() => {
          setShowSuccessMessage(false);
        }, 3000);
      })
      .catch((error) => {
        console.log(error.text);
      });
  };

  return (
    <section
      id="Collab"
      className="pt-32 pb-20 px-8 relative"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${backgroundImg})`,
        backgroundSize: "cover",
      }}
    >
      <div className="flex-col md:flex-row flex justify-center items-center gap-x-20">
        {/* Name */}
        <form
          onSubmit={sendEmail}
          ref={form}
          className="flex flex-col mb-5 mx-auto md:mx-0 "
        >
          {showErrorMessage && (
            <div className="flex h-12 w-full  md:w-5/4 bg-red relative mt-3 mb-3 rounded">
              <p className="absolute inset-0 flex items-center justify-start text-white md:text-lg text-sm font-semibold text-left ml-2">
                Please fill in all the required fields!
              </p>
            </div>
          )}
          {showSuccessMessage && (
            <div className="flex h-12 w-full  md:w-5/4 bg-[#149414] relative mt-3 mb-3 rounded">

              <p className="absolute inset-0 flex items-center justify-start text-white md:text-lg text-sm font-semibold text-left ml-2">
               Your Email has been sent successfully !

              </p>
            </div>
          )}
          <div className="flex md:flex-row flex-col w-full justify-start md:justify-between pr-2">
            {/* First Name */}
            <div className="flex flex-col w-2/3 md:w-80 mb-2 mr-2 ">
              <label className="md:text-lg  text-sm font-semibold text-white text-left mt-2 mb-2">
                Nom et Prénom
              </label>
              <input
                className="w-full bg-white bg-opacity-30 text-black rounded-2xl font-semibold placeholder-black p-3 border border-gray-400 "
                type="text"
                id="name"
                name="name"
                placeholder="Full name"
              />
            </div>
            {/* Email */}
            <div className="flex flex-col w-2/3 md:w-80 mb-2 ">
              <label className="md:text-lg text-sm font-semibold text-white text-left mt-2 mb-2">
                Votre adresse email
              </label>
              <input
                className="w-full bg-white text-black bg-opacity-30 rounded-2xl font-semibold placeholder-black p-3 border border-gray-400 "
                type="email"
                id="email"
                name="email"
                placeholder="Email address"
                autoComplete="email"
              />
            </div>
          </div>
          <div className="flex flex-col h-full w-full md:w-5/4 mb-2">
            <label className="md:text-lg text-sm font-semibold mb-2 text-white text-left mt-2">
              Choisir une option
            </label>
            {/* Wrapper Div with Custom Arrow Styling */}
            <div className="relative w-full bg-black bg-opacity-30 rounded-2xl border border-gray-400">
              <select
                className="md:w-full w-[80%] bg-transparent rounded-2xl font-semibold p-4 appearance-none  "
                defaultValue="placeholder"
              >
                <option value="placeholder" disabled 
                                  className="text-black font-semibold text-xs md:text-xl"
                                  >
                  Le sujet du mail est... (exemple : publicité et convention)
                </option>
                <option
                  value="reservation de spectacle"
                  className="text-black font-semibold text-sm md:text-xl"
                >
                  Réservation de spectacle
                </option>
                <option
                  value="publicité et convention"
                  className="text-black font-semibold text-sm md:text-xl"
                >
                  Publicité et convention
                </option>
                <option
                  value="casting/cinema"
                  className="text-black font-semibold text-sm md:text-xl"
                >
                  Casting/Cinéma
                </option>
              </select>
              {/* Custom Dropdown Arrow */}
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-white">
                <svg
                  className="fill-current h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M5.293 7.293a1 1 0 0 1 1.414 0L10 10.586l3.293-3.293a1 1 0 1 1 1.414 1.414l-4 4a1 1 0 0 1-1.414 0l-4-4a1 1 0 0 1 0-1.414z" />
                </svg>
              </div>
            </div>
          </div>

          <div className="flex flex-col w-full md:w-5/4 mb-2">
            <label className="md:text-lg text-sm font-semibold mb-2 text-white text-left mt-2">
              Contenu du mail
            </label>
            {/* Textarea */}
            <textarea
              className="w-full bg-white text-black bg-opacity-30 rounded-2xl font-semibold placeholder-black p-3 border border-gray-400 "
              id="collaboration"
              name="collaboration"
              placeholder=""
              rows="4"
              cols="50"
            />
          </div>

          <div className="flex  w-full  justify-center">
            <button
              type="submit"
              className="p-1 bg-blue bg-opacity-50 hover:bg-opacity-100 font-semibold text-gray rounded-md  w-11/12"
            >
              Envoyer !
            </button>
          </div>
        </form>
        <div className="flex flex-col  mb-2 relative">
          <img
            src={contactimg}
            alt="additional information"
            className="hidden md:block rounded-full border-4 border-dashed border-blue mt-15"
            style={{ width: "400px", height: "400px" }}
          />
        </div>
      </div>
    </section>
  );
};

export default Contact;
