import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y, EffectCube } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import ep1 from "../assets/ep1.jpg";
import ep2 from "../assets/ep2.jpg";
import ep3 from "../assets/ep3.jpg";
import ep4 from "../assets/ep4.jpg";
import ep5 from "../assets/ep5.jpg";






const slides = [
  {
    id: 1,
    youtubeId: 'cx_Tc6KQ9ME',
    thumbnail: ep1,
    title: '',
  },
  {
    id: 2,
    youtubeId: '7qxXjHBtAYE',
    thumbnail: ep2,
    title: '',
  },
  {
    id: 3,
    youtubeId: 'tXMy5V1Z1kM',
    thumbnail: ep3,
    title: '',
  },
  {
    id: 4,
    youtubeId: '6FxzEivT6Uc',
    thumbnail: ep4,
    title: '',
  },
  {
    id: 5,
    youtubeId: 'Ol1P7EDn6xM',
    thumbnail: ep5,
    title: '',
  },
];

export const Slider = () => {
  return (
    <Swiper
      className="w-[100%]"
      loop="true"
      modules={[Navigation, Pagination, Scrollbar, A11y, EffectCube]}
      spaceBetween={50}
      slidesPerView={3}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => console.log(swiper)}
      breakpoints={{
        320: { slidesPerView: 1, spaceBetween: 20 },
        530: { slidesPerView: 2, spaceBetween: 30 },
        980: { slidesPerView: 3, spaceBetween: 50 },
        1200: { slidesPerView: 4, spaceBetween: 60 }
      }}
    >
      {slides.map((slide) => (
        <SwiperSlide key={slide.id}>
          <a href={`https://www.youtube.com/watch?v=${slide.youtubeId}`} target="_blank" rel="noopener noreferrer">
            <div className=" inline-block overflow-hidden hover:cursor-pointer hover:scale-105
            group" onMouseEnter={() => {}}
            onMouseLeave={() => {}}>
            <img src={slide.thumbnail} alt="Video Thumbnail" className="w-full  rounded-lg h-auto transform  group-hover:opacity-40 transition-transform duration-300 ease-in-out " />
            <div className="absolute inset-0 flex flex-col justify-center items-center text-center text-white p-6">
              <p className="text-xl text-black font-semibold rounded-xl px-4 bg-gray-100 bg-opacity-50">Click to watch</p>
            </div>
            </div>
            
          </a>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
