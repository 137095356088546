import Card from "../components/Card";
import weldi from "../assets/weldi.jpg";
import oiseau from "../assets/oiseau.jpg";

import noura from "../assets/noura.jpg";

import decembre from "../assets/decembree.jpg";

import fil from "../assets/lefil.jpg";

const cardData = [
  {
    imageUrl: oiseau,
    text: "l'oiseau bleu العصفور الازرق",
    videoUrl: "https://artify.tn/fr/media/5cd43214ffd9d07ba12c2fa0/loiseau-bleu",
  },
  {
    imageUrl: fil,
    text: "Le Fil",
    videoUrl: "https://www.imdb.com/title/tt1302559/",
  },
  {
    imageUrl: weldi,
    text: "Mon cher enfant ولدي",
    videoUrl: "https://cinematunisien.com/blog/2019/07/06/weldi-mon-cher-enfant/",
  },
  {
    imageUrl: decembre,
    text: "Fin Décembre اخر ديسمبر",
    videoUrl: "https://artify.tn/fr/media/5f0c4749475d087161d00259/fin-dcembre",
  },
  {
    imageUrl: noura,
    text: "Noura rêve نورة تحلم",
    videoUrl: "https://artify.tn/fr/media/5da487cc4250f04aa60095f0/noura-rve",
  },
 
];

const Movies = () => {
  return (
    <section
      id="movies"
      className="ss:px-24 px-10 py-24  max-sm:mt-12 bg-[#060706]"
    >
      <div className="flex flex-col justify-start ">
        <h2 className="md:text-5xl ss:text-3xl text-2xl  font-semibold text-start">
          Films
        </h2>
      </div>

      <div className="mt-16 grid lg:grid-cols-3 sm:grid-cols-2  grid-cols-1 sm:gap-8 gap-14">
        {cardData.map((card, index) => (
          <Card
            key={index}
            imageUrl={card.imageUrl}
            text={card.text}
            videoUrl={card.videoUrl}
          />
        ))}
      </div>
    </section>
  );
};

export default Movies;
