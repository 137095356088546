import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y, EffectCube } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import samedi from "../assets/samedi.jpg";
import sidrjel from "../assets/sidrjel.jpg";

import jarri from "../assets/jarri.jpg";
import dafaa from "../assets/dafaa.jpg";
import ala from "../assets/ala.jpg";




const slides = [
  {
    id: 1,
    youtubeId: '7uuAePxI9CE',
    thumbnail: samedi,
    title: '',
  },
  {
    id: 2,
    youtubeId: 'MI6uHVPGBIY',
    thumbnail: jarri,
    title: '',
  },
  {
    id: 3,
    youtubeId: 'mAMRmViBgqU',
    thumbnail: dafaa,
    title: '',
  },
  {
    id: 4,
    youtubeId: 'i53d98sW0k',
    thumbnail: sidrjel,
    title: '',
  },
  {
    id: 5,
    youtubeId: '8ha0tYkab-I',
    thumbnail: ala,
    title: '',
  },
];

export const Slider = () => {
  return (
    <Swiper
      className="w-[100%]"
      loop="true"
      modules={[Navigation, Pagination, Scrollbar, A11y, EffectCube]}
      spaceBetween={50}
      slidesPerView={3}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => console.log(swiper)}
      breakpoints={{
        320: { slidesPerView: 1, spaceBetween: 20 },
        530: { slidesPerView: 2, spaceBetween: 30 },
        980: { slidesPerView: 3, spaceBetween: 50 },
        1200: { slidesPerView: 3, spaceBetween: 60 }
      }}
    >
      {slides.map((slide) => (
        <SwiperSlide key={slide.id}>
          <a href={`https://www.youtube.com/watch?v=${slide.youtubeId}`} target="_blank" rel="noopener noreferrer">
            <div className=" inline-block overflow-hidden hover:cursor-pointer hover:scale-105
            group" onMouseEnter={() => {}}
            onMouseLeave={() => {}}>
            <img src={slide.thumbnail} alt="Video Thumbnail" className="w-full  rounded-lg h-auto transform  group-hover:opacity-40 transition-transform duration-300 ease-in-out " />
            <div className="absolute inset-0 flex flex-col justify-center items-center text-center text-white p-6">
              <p className="text-xl text-black font-semibold rounded-xl px-4 bg-gray-100 bg-opacity-50">Click to watch</p>
            </div>
            </div>
            
          </a>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
