import React, { useEffect } from 'react';
import './particles.css'; 

const ParticleAnimation = () => {
  useEffect(() => {
    const styleSheet = document.createElement('style');
    document.head.appendChild(styleSheet);

    const total = 300;
    const orbSize = 100; // This should be consistent with your CSS variable --orb-size
    const time = '14s';
    const baseHue = 200; // Adjust base hue as needed

    for (let i = 1; i <= total; i++) {
      const z = Math.random() * 360;
      const y = Math.random() * 360;
      const hue = (40 / total * i) + baseHue; 
      const delay = i * 0.01; 

      const keyframes = `
        @keyframes orbit${i} {
          20% {
            opacity: 1;
          }
          30% {
            transform: rotateZ(-${z}deg) rotateY(${y}deg) translateX(${orbSize}px) rotateZ(${z}deg);
          }
          80% {
            transform: rotateZ(-${z}deg) rotateY(${y}deg) translateX(${orbSize}px) rotateZ(${z}deg);
            opacity: 1;
          }
          100% {
            transform: rotateZ(-${z}deg) rotateY(${y}deg) translateX(${orbSize * 3}px) rotateZ(${z}deg);
            opacity: 0;
          }
        }
      `;

      
      const particleCSS = `
        .c:nth-child(${i}) {
          animation: orbit${i} ${time} infinite linear;
          animation-delay: ${delay}s;
          background-color: hsla(${hue}, 100%, 50%, 1);
        }
      `;

      styleSheet.appendChild(document.createTextNode(keyframes));
      styleSheet.appendChild(document.createTextNode(particleCSS));
    }
  }, []);

  const particles = Array.from({ length: 300 }, (_, index) => (
    <div key={index} className="c" />
  ));

  return ( 
    <div className=''>
            <div className="wrap">{particles}</div>

    </div>
  
            
            );
};

export default ParticleAnimation;
